import React from "react"
import { ThemeProvider } from "@material-ui/styles"
import { IntlProvider, addLocaleData } from "react-intl"
import PageTransition from "gatsby-v2-plugin-page-transitions"
import { CssBaseline, NoSsr } from "@material-ui/core"
import Theme from "../theme"
import {
  CartProvider,
  CheckoutComponent,
  MobileOrientation,
} from "../../../components"
import locale_es from "react-intl/locale-data/es"
import LeafLeft from "../images/leaf left.png"
import LeafRight from "../images/leaf right.png"
import locale_en from "react-intl/locale-data/en"
import "../global_styles/global.css"

addLocaleData([...locale_en, ...locale_es])

export default ({ pageContext, data, location }) => {
  const langKey = pageContext.locale
  const i18nmessages = require(`../messages/index/${langKey}`)
  let reference = React.createRef()
  const queryString = require("query-string")
  const parsedQuery = queryString.parse(location.search)
  const {
    transitionTime,
    defaultTransitionTime,
  } = require("../../../constants/randomConsts")
  return (
    <PageTransition
      transitionTime={
        parsedQuery.transition ? transitionTime : defaultTransitionTime
      }
    >
      <IntlProvider locale={langKey} messages={i18nmessages}>
        <React.Fragment>
          <CssBaseline />
          <ThemeProvider theme={Theme}>
            <MobileOrientation />
            <CartProvider cartId={undefined}>
              <CheckoutComponent
                header={data.header.edges[0].node}
                footer={data.footer.edges[0].node}
                checkout={data.checkout.edges[0].node}
                reference={reference}
                bgImgLeft={LeafLeft}
                bgImgRight={LeafRight}
                langKey={langKey}
              />
            </CartProvider>
          </ThemeProvider>
        </React.Fragment>
      </IntlProvider>
    </PageTransition>
  )
}

export const pageQuery = graphql`
  query($locale: String!) {
    header: allContentfulHeader(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          mainLogo {
            file {
              url
            }
          }
          shoppingCartLogo {
            file {
              url
            }
          }
        }
      }
    }
    checkout: allContentfulCheckoutPage(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          addressTitle {
            fontColor
            fontSize
            fontFamily
            fontWeight
            letterSpacing
            lineHeight
            textTransform
            content {
              json
            }
          }
          apartmentTitle {
            fontColor
            fontSize
            fontFamily
            fontWeight
            letterSpacing
            lineHeight
            textTransform
            content {
              json
            }
          }
          cityTitle {
            fontColor
            fontSize
            fontFamily
            fontWeight
            letterSpacing
            lineHeight
            textTransform
            content {
              json
            }
          }
          receiveUpdatesText {
            receiveUpdatesText
          }
          countryTitle {
            fontColor
            fontSize
            fontFamily
            fontWeight
            letterSpacing
            lineHeight
            textTransform
            content {
              json
            }
          }
          emailAddressTitle {
            fontColor
            fontSize
            fontFamily
            fontWeight
            letterSpacing
            lineHeight
            textTransform
            content {
              json
            }
          }
          firstNameTitle {
            fontColor
            fontSize
            fontFamily
            fontWeight
            letterSpacing
            lineHeight
            textTransform
            content {
              json
            }
          }
          identificationTitle {
            fontColor
            fontSize
            fontFamily
            fontWeight
            letterSpacing
            lineHeight
            textTransform
            content {
              json
            }
          }
          lastNameTitle {
            fontColor
            fontSize
            fontFamily
            fontWeight
            letterSpacing
            lineHeight
            textTransform
            content {
              json
            }
          }
          pageTitle {
            fontColor
            fontSize
            fontFamily
            fontWeight
            letterSpacing
            lineHeight
            textTransform
            content {
              json
            }
          }
          phoneNumberTitle {
            fontColor
            fontSize
            fontFamily
            fontWeight
            letterSpacing
            lineHeight
            textTransform
            content {
              json
            }
          }
          postalCodeTitle {
            fontColor
            fontSize
            fontFamily
            fontWeight
            letterSpacing
            lineHeight
            textTransform
            content {
              json
            }
          }
          provinceTitle {
            fontColor
            fontSize
            fontFamily
            fontWeight
            letterSpacing
            lineHeight
            textTransform
            content {
              json
            }
          }
        }
      }
    }
    footer: allContentfulFooter(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          copyright {
            redirectLink
            titleText {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
          }
          discoverLogo {
            file {
              url
            }
          }
          amexLogo {
            file {
              url
            }
          }
          facebookLogo {
            file {
              url
            }
          }
          instagramLogo {
            file {
              url
            }
          }
          masterCardLogo {
            file {
              url
            }
          }
          payULogo {
            file {
              url
            }
          }
          visaLogo {
            file {
              url
            }
          }
          youTubeLogo {
            file {
              url
            }
          }
        }
      }
    }
  }
`
